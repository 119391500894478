import axios from "axios";
import {v4 as uuidv4} from "uuid";
import {retry} from 'ts-retry-promise';

const signinRoot = import.meta.env.VITE_APP_SIGNIN_ROOT;

const sleep = (milliseconds: number) => {
    return new Promise(resolve => setTimeout(resolve, milliseconds))
}

async function saveCookie(id: string) {
    try {
        await axios.get(signinRoot + "/v1/save-cookie/" + id, {
            withCredentials: true,
        });
    } catch (error) {
        // This WILL fail, it's expected
    }

    await sleep(2000);
}

async function readCookie(id: string) {
    return await axios.get(signinRoot + "/v1/get-cookie/" + id);
}

type Cookies = {
    [key: string]: string;
}

async function getCookiesImpl() {
    const id = uuidv4();
    return await saveCookie(id).then(async _ => {
        let response = await readCookie(id);
        return response.data.cookies as Cookies;
    })
}

export async function getCookies() {
    return await retry(getCookiesImpl, {
        retries: 10,
        delay: 1000,
        backoff: "EXPONENTIAL",
        maxBackOff: 10000,
    });
}
