import './App.css'
import AppSignedIn from './signedin.tsx'
import { ClerkProvider, SignedIn, SignedOut, SignIn } from "@clerk/clerk-react";

const clerkPubKey = import.meta.env.VITE_APP_CLERK_PUB_KEY;

function App() {
  if (clerkPubKey === undefined) {
    console.error("Clerk public key not set");
    return <div>CLERK KEY NOT SET!</div>;
  }

  return (
    <ClerkProvider publishableKey={clerkPubKey}>
        <SignedIn>
            <AppSignedIn />
        </SignedIn>
        <SignedOut>
            {/* Adding a redirectUrl prevents occasional redirection to console.withampersand.com */}
            <SignIn redirectUrl={window.location.href} />
        </SignedOut>
    </ClerkProvider>
  )
}

export default App
